<template>
  <el-dialog title="个人密码设置" :close-on-click-modal="false" v-dialogDrag :visible.sync="dialogSetPassword" size="tiny"
             :modal="false" @close="handleSetPasswordModalClose">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item label="旧密码" :label-width="formLabelWidth" prop="oldPassword">
        <el-input :type="isShowOld ? 'password' : 'text'" placeholder="请输入旧密码" v-model="form.oldPassword"
                  auto-complete="off">
          <template slot="suffix">
            <img style="width: 15px;" @click="isShowOld = !isShowOld" alt=""
                 :src="isShowOld ? require('../../assets/img/EyeInvisible.png') : require('../../assets/img/mimakejian.png')"/>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="新密码" :label-width="formLabelWidth" prop="password">
        <el-input :type="isShowNew ? 'password' : 'text'" placeholder="请输入新密码" v-model="form.password"
                  auto-complete="off">
          <template slot="suffix">
            <img style="width: 15px;" @click="isShowNew = !isShowNew" alt=""
                 :src="isShowNew ? require('../../assets/img/EyeInvisible.png') : require('../../assets/img/mimakejian.png')"/>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" :label-width="formLabelWidth" prop="pass1">
        <el-input :type="isShowCon ? 'password' : 'text'" placeholder="两次密码请保持一致" v-model="form.pass1" auto-complete="off">
          <template slot="suffix">
            <img style="width: 15px;" @click="isShowCon = !isShowCon" alt=""
                 :src="isShowCon ? require('../../assets/img/EyeInvisible.png') : require('../../assets/img/mimakejian.png')"/>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogSetPassword = false">取消</el-button>
      <el-button type="primary" @click="setpassword">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {setPassword} from "@/api/login";
import { exitApp } from '@/utils/auth'

export default {
  name: "ResetPwd",
  data() {
    const validatePass = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次密码请保持一致"));
      } else {
        callback();
      }
    };
    return {
      isShowOld: true,
      isShowNew: true,
      isShowCon: true,
      dialogSetPassword: false,
      form: {
        oldPassword: "",
        password: "",
        pass1: ""
      },
      formLabelWidth: "80px",
      rules: {
        oldPassword: [
          {required: true, message: "请输入旧密码", trigger: "blur"}
        ],
        password: [
          {required: true, message: "请输入新密码", trigger: "blur"}
        ],
        pass1: [
          {
            required: true,
            trigger: ["change","blur"],
            validator: validatePass
          }
        ]
      }
    }
  },
  methods: {
    init() {
      this.form = {
        oldPassword: "",
        password: "",
        pass1: "",
      };
      this.$nextTick(() => {
        this.dialogSetPassword = true;
      });
    },
    setpassword() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 数据检验通过进行修改
          setPassword(this.form).then(response => {
            this.$confirm("修改成功,请使用新密码登录",'提示', {
              confirmButtonText: 'OK',
              type: 'success',
              closeOnClickModal: false,
              closeOnPressEscape:false,
              closeOnHashChange:false,
              showCancelButton: false,
              showClose: false
            }).then(res => {
              exitApp();
            })
          })
        }
      });
    },
    handleSetPasswordModalClose() {
      this.$refs.ruleForm.resetFields();
      this.isShowOld = true;
      this.isShowNew = true;
      this.isShowCon = true;
    },
  }
}
</script>

<style scoped>

</style>
