import Vue from "vue";
import Router from "vue-router";
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
const routerReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(error => error)
}
Vue.use(Router);
export const errorRoute = [
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
];

import home from './modules/home'
import {MANUFAC,MANUFACT} from './modules/ManufacturerFactory'
import {SUPPLIE,SUPPLIER} from './modules/SupplierFactory'
import {BASEUSE,BASEUSER} from './modules/organizationalStructure'
import {CODER,CODERM} from './modules/CodeRuleManagement'
import {CATEGOR,CATEGORY} from './modules/CategoryManagement'
import {SYMBOLNE,SYMBOLNEW} from './modules/TheSymbolLibrary'
import {ENCAPLI,ENCAPLIB,THREELIB} from './modules/EncapsulationLibrary'
import {ELECTRONI,ELECTRONIC,DATASHEET} from './modules/ElectronicComponents'
import {FLOW,FLO} from './modules/ApprovalProcess'
import {MESSAGE,MESSAG} from './modules/MessageCenter'
import {OPM,OPMA} from './modules/operationMaintenance'
import {CBB,CB} from './modules/CBB'
import {DSNREVIE,DSNREVIEW} from './modules/DSNREVIEW'
import {BOARDBOMA,BOARDCARDBOM} from './modules/BoardBom'
import {SYSTEMINTEGRATION,SYSTEMSETTING} from "./modules/SystemIntegration";
import {ALTIUMFOOTPRINT,ALTIUMSYMBOL} from "./modules/Altium";
import {QUALITYINFOMATION} from "./modules/QualityInformationManagement"
import {PRODUCTDESIGNMANAGE} from "./modules/productDesignManage"
import {COM} from "./modules/COM"
import {TOOLS} from "./modules/Tools";
import {SYSTEMMONITOR} from './modules/SystemMonitor'
// login:"no" 不需登录就能访问
// local:"home" 首页 不需要权限就能访问
// 固定的路由
export const constantRoutes = [
  {
    path: '/redirect',
    hidden: true,
    meta: {
      noCache: true,
      login:"no"
    },
    children: [{
      path: '/redirect/:path*',
      component: () => import('@/views/redirect/index')
    }]
  },
  ...home,
  //  ...BOARDCARDBOM,
  {
    path: "/404",
    name: "404",
    meta: {
      noCache: true,
      login:"no"
    },
    component: () => import("@/views/errorPage/404"),
    hidden: true,
  },
  {
    path: "/control",
    name: "control",
    meta: {
      login:"no"
    },
    component: () => import("@/views/redirect/control"),
    hidden: true,
  },
  {
    path: "/clientLogin",
    name: "clientLogin",
    meta: {
      login:"no"
    },
    component: () => import("@/views/redirect/clientLogin"),
    hidden: true,
  },
];
function setForm(arr,key,type){
  arr.forEach(v=>{
    if(!v.meta){
      v.meta = {}
    }
    v.meta.menuFrom = key;
    v.meta.menuType = type;
    if(v.children&&v.children.length>0){
      setForm(v.children,key,type)
    }
  })
}
const elib = [
  ...ELECTRONIC,
  ...DATASHEET,
  ...FLOW,
  ...CATEGORY,
  ...CODERM,
  ...MANUFACT,
  ...SUPPLIER,
  ...SYMBOLNEW,
  ...ENCAPLIB,
  ...ALTIUMSYMBOL,
  ...ALTIUMFOOTPRINT,
  ...THREELIB,
  ...DSNREVIEW,
  ...PRODUCTDESIGNMANAGE,
  ...BOARDCARDBOM,
  ...QUALITYINFOMATION,
  ...MESSAGE,
  ...BASEUSER,
  ...SYSTEMSETTING,
  ...SYSTEMINTEGRATION,
  ...OPMA,
  ...TOOLS,
  ...SYSTEMMONITOR,
];
setForm(elib,"元器件","cms")
const cbb = [...CBB];
setForm(cbb,"模块电路","cbb")
const com = [...COM];
setForm(com,"通用电路","com")
export const addRouteByLogin = [
  ...elib,...cbb,...com
]
// export const addRouteByLogin = [
//   ...SYMBOLNEW
// ]
let eli = [
  ...MANUFAC,...SUPPLIE,...BASEUSE,...CODER,...CATEGOR,...SYMBOLNE,...ENCAPLI,...ELECTRONI,...FLO,...MESSAG,...OPM,...DSNREVIE,...BOARDBOMA,
]
setForm(eli,"元器件","cms")
let cb = [...CB]
setForm(cb,"模块电路","cbb")
export const asyncRoutes = [...eli,...cb];
export const constantRouterMap = []
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
const createRouter = () => new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
const router = createRouter()

export default router
