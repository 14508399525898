import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const MANUFAC = [
  {
    path: '/MANUFAC',
    component: Layout,
    redirect: '/MANUFAC/manufacturer/',
    name: '制造商S',
    children: [
      {
        path: 'manufacturer',
        component: MainLayout,
        name: '制造商物料',
        redirect: '/MANUFAC/manufacturer/materialList',
        children: [
          { path: 'materialList', component:  () => import('@/views/ManufacturerFactory/manufact/manufacturer/materialList'), name: '物料列表',  },
        ]
      },
    ]
  },
];
export const MANUFACT = [
  {
    path: '/MANUFACT',
    component: Layout,
    redirect: '/MANUFACT/manufacturerList/',
    name: '制造商',
    menuName:'制造商',
    meta: {
      title: "制造商",
      icon:"fa fa-cubes",
    },
    children: [
      { path: 'manufacturerList', component:  () => import('@/views/ManufacturerFactory/manufact/manufacturer/manufacturerList'),hidden:true, name: '制造商',menuName:'制造商',meta:{title:"制造商",activeMenu:"/MANUFACT"}},
    ]
  },
];