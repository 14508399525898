import { readMyRoleBtnList} from '@/api/login'
// import { configFilePath,mkdirsSync,getConfigIp} from '@/utils/tools';
// let ipObj= getConfigIp();
// let htp = "";
// if(process.env.NODE_ENV !== "development"){
//   htp = `http://${ipObj.url}:${ipObj.prop}/`;
// }
const fs = require("fs");
var path = require('path');
function getSessionButton(){
  return new Promise((resolve, reject)=>{
    let roleBtn = sessionStorage.getItem('roleButton')
    if(roleBtn){
      resolve(JSON.parse(roleBtn))
    }else{
      readMyRoleBtnList({}).then(res=>{
        sessionStorage.setItem('roleButton',JSON.stringify(res.data));
        resolve(res.data)
      }).catch(err=>{
        reject();
      })
      // reject();
    }
  })
}
const myData = {
  namespaced: true,
  state: {
    ubtn: {},
    configInfo:{},
  },
  mutations: {
    SET_RoleButton: (state, btnobj) => {
      state.ubtn = btnobj
    },
    SET_configInfo: (state, configInfo) => {
      state.configInfo = configInfo
    },
  },
  actions: {
    // 设置button
    getUserRoleButton({ commit }, data) {
      // commit('SET_RoleButton', data);
      getSessionButton().then(res=>{
        let btnobj = {};
        btnobj.setIO = true;
        res.forEach((a)=>{
          btnobj[a.remark] = a;
        })
        commit('SET_RoleButton', btnobj)
        });
    },
    getConfigInfo({ commit }, data) {
      return new Promise((resolve,reject) => {
        //本地配置文件存放路径 , 开发和生产环境不同
          let configpath = path.join(data.exePath,'/config/config.json')
          fs.readFile(configpath, "utf-8", function(error, filedatas) {
            if (error) {
              commit('SET_configInfo', {})

            } else{

              let obj = JSON.parse(filedatas);
              commit('SET_configInfo', obj);
            }
          });

      })
    },
  }
}
export default myData
