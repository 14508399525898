const fs = require("fs");
const path = require("path");
const http = require('http');
// const { remote } = require('electron');
import {exitApp, getToken} from "./auth";
import {Message} from "element-ui";

const querystring = require('querystring');

export function deepCopy(obj) {
    var result = Array.isArray(obj) ? [] : {};
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                result[key] = deepCopy(obj[key]); //递归复制
            } else {
                result[key] = obj[key];
            }
        }
    }
    return result;
}

/**
 *
 * @param {*} dirname "D:/Cadence/513/WW/test"
 * 创建文件夹目录
 * 返回值为 true
 */
export function mkdirsSync(dirname) {
    if (fs.existsSync(dirname)) {
        return true;
    } else {
        if (mkdirsSync(path.dirname(dirname))) {
            fs.mkdirSync(dirname);
            return true;
        }
    }
}


/**
 *
 * @param {*} uri "http://aa.com/1.txt"
 * @param {*} dest ""D:/Cadence/513/WW/test/1.txt"
 * 创建文件夹目录
 * 返回值为 Promise then catch
 */
export function downloadFileAsync(uri, dest) {
    return new Promise((resolve, reject) => {
        // 确保dest路径存在

        // fs.statSync(dest, function (exists) {
        //   if (exists) {
        //     cb(false)
        //   }else{
        //     cb(true)
        //   }
        // })
        mkdirsSync(path.dirname(dest))
        const file = fs.createWriteStream(dest);
        http.get(uri, (res) => {
            if (res.statusCode !== 200) {
                reject(res.statusCode);
                return;
            }

            res.on('end', () => {
            });

            // 进度、超时等

            file.on('finish', () => {
                file.close(resolve);
                resolve(res)
            }).on('error', (err) => {
                fs.unlink(dest);
                reject(err.message);
            })

            res.pipe(file);
        });
    });
}

/**
 *
 * @param {*} options 请求对象
 * @param {*} data 参数
 * @param {*} dest 文件存放位置
 *
 * 创建文件夹目录
 * 返回值为 Promise then catch
 */
export function nodeHttpGet(options, cont, dest) {
    return new Promise((resolve, reject) => {
        mkdirsSync(path.dirname(dest))
        var content = querystring.stringify(cont);
        var op = {
            hostname: getConfigIp().url,
            port: getConfigIp().prop,
            path: options.path + content,
            method: "GET",
            headers: {
                'token': getToken()
            }
        };
        var req = http.request(op, (res) => {
            if (res.statusCode !== 200) {
                reject(res.statusCode);
                return;
            }
            if (res.headers['content-type'] == 'application/json;charset=UTF-8') {
                console.log("返回的不是文件")
            }
            if (res.headers['content-type'] == 'text/html; charset=utf-8') {
                console.log("返回的不是文件")
            } else {
                const file = fs.createWriteStream(dest);
                file.on('finish', () => {
                    file.close();
                    resolve(res)
                }).on('error', (err) => {
                    fs.unlink(dest);
                    reject(err.message);
                })

                res.pipe(file);
            }

            res.on('data', function (chunk) {
                if (res.headers['content-type'] == 'application/json;charset=UTF-8') {
                    console.log(chunk.toString())
                    reject(chunk.toString());
                    return false
                } else if (res.headers['content-type'] == 'text/html; charset=utf-8') {
                    console.log(chunk.toString())
                    reject(chunk.toString());
                    return false
                }
            });
            res.on('end', () => {
            });

        });
        req.on('error', function (e) {
            console.log('problem with request: ' + e.message);
        });
        req.end();
    });
}

/**
 *
 * @param {*} options 请求对象
 * @param {*} data 参数
 * @param {*} dest 文件存放位置
 *
 * 创建文件夹目录
 * 返回值为 Promise then catch
 */
export function nodeHttpPost(options, cont, dest) {
    return new Promise((resolve, reject) => {
        mkdirsSync(path.dirname(dest));
        var op = {
            hostname: getConfigIp().url,
            port: getConfigIp().prop,
            path: options.path,
            method: "POST",
            headers: {
                'token': getToken(),
                'content-type': 'application/json'
            }
        };

        var req = http.request(op, (res) => {
            if (res.statusCode !== 200) {
                reject(res.statusCode);
                return;
            }
            if (res.headers['content-type'] == 'application/json;charset=UTF-8') {

                // return false
            } else {

                const file = fs.createWriteStream(dest);
                file.on('finish', () => {
                    file.close();
                    resolve(res)
                }).on('error', (err) => {
                    console.log(err)
                    try {
                        fs.unlink(dest);
                    } catch (error) {

                    }

                    reject(err);
                })

                res.pipe(file);
            }

            res.on('data', function (chunk) {
                if (res.headers['content-type'] == 'application/json;charset=UTF-8') {
                    reject(chunk.toString());
                    return false
                }
            });

        });
        let content = cont;
        console.log(content)
        console.log(cont)
        req.write(JSON.stringify(content));
        req.on('error', function (e) {
            console.log('problem with request: ' + e.message);
        });
        req.end();
    });
}

/**
 *
 * @param {*} version "17.2"
 *
 *
 */
export function getExePath(version) {
    let pa = require("path");
    // let remo = require('electron').remote;
    var exePath = pa.dirname(remo.app.getPath('exe'));
    if (process.env.NODE_ENV == "development") {
        exePath = "D:\\test\\Electron";
        return exePath
    } else {
        return exePath
    }
}

/**
 *
 * @param {*} version "17.2"
 *
 * 获取配置IP
 */
export function getConfigIp() {
    let ffs = require("fs")
    let pa = require("path");
    let exepa = getExePath();
    let ipconfp = pa.join(exepa, "/config/ipconfig.json");
    let json = ffs.readFileSync(ipconfp);
    let ipObj = JSON.parse(json.toString());
    return ipObj ? ipObj : {};
}

/**
 *
 * @param name
 * @param type toLine | toHump 驼峰式转下划线: toLine 下划线转驼峰式: toHump
 * @returns {string|*}
 */
export function humpOrLine(name, type) {
    if(name==null){
        return
    }
    if (type === 'toLine') {
        let field = name.replace(/([A-Z])/g, '_$1').toLowerCase();
        if (!field.includes("_")) {
            field = `${field}_`;
        }
        return field;
    } else if (type === 'toHump') {
        let newname =   name.replace(/\_(\w)/g, function (all, letter) {
            return letter.toUpperCase();
        });
        return newname.replace(/(\w)\_/g,function (all,letter){
            return letter
        })
    }
}

export function toTree(data) {
    let result = [];
    if (!Array.isArray(data)) {
        return result;
    }
    data.forEach((item) => {
        delete item.children;
    });
    let map = {};
    data.forEach((item) => {
        map[item.id] = item;
    });
    data.forEach((item) => {
        let parent = map[item.parentId];
        if (parent) {
            (parent.children || (parent.children = [])).push(item);
        } else {
            result.push(item);
        }
    });
    return result;
}

export function toList(arr) {
    let result = []
    arr.forEach(item => {
        var res = JSON.parse(JSON.stringify(item)) // 先克隆一份数据作为第一层级的填充
        delete res['children']
        result.push(res)
        if (item.children instanceof Array && item.children.length > 0) { // 如果当前child为数组并且长度大于0，才可进入flag()方法
            result = result.concat(toList(item.children))
        }
    })
    return result
}

/**
 * altium 符号多 part 转换
 * @param num
 * partCount>2是多part，=3，是A B。=4是 ABC,>28是AA AB AC
 */
export function numToPart(num) {
    const partCount = 2;
    let n = parseInt(num);
    let result = [];
    if (!n || n <= partCount) {
        return result;
    }
    if (n > partCount) {
        const charA = 65;
        const len = 26;
        const chart = charA - partCount;
        for (let i = partCount;i <= n;i++) {
            if (i < len + partCount) {
                result.push(String.fromCharCode(chart + i));
            } else if (i >= len + partCount) {
                let index = Math.floor(result.length / len)-1;
                result.push(result[index]+String.fromCharCode(chart-len*(index+1) + i));
            }
        }
        return result;
    }
}

/**
 * altium 图形获取缩小比例
 * @param wid1
 * @param wid2
 * @param he1
 * @param he2
 * @returns {number}
 */
export function inToPx(wid1,wid2,he1,he2) {
    let w = (wid2 - 8) / wid1;
    let h = (he2 - 8) / he1;
    let x = h;
    if (w < h) {
        x = w;
    }
    return x;
}

/**
 * 替换svg中类名，避免符号与封装图形样式冲突
 * @param res
 * @returns {*|string}
 */
export function renameSvgClassName(res) {
    let imageSvg = "";
    let a = res.indexOf("<style type=\"text/css\"");
    let b = res.indexOf("</style>");
    let styleStr = res.substring(a,b).replace(/\s/g,"").split("}");
    let arr = [];
    styleStr.forEach(v => {
        let c = v.indexOf(".");
        let d = v.indexOf("{");
        if (v.substring(c+1,d)) {
            arr.push(v.substring(c+1,d))
        }
    })
    let str = res;
    arr.forEach(v => {
        let reg = new RegExp(v,"g");
        str = str.replace(reg,v+'sym');
    })
    imageSvg = str;
    return imageSvg;
}

/**
 * 处理 FileAttribute 参数
 * 从外层找名称，内层找该名称的状态 外侧有，内层无，默认正式
 * @param data
 */
export function dealFileAttribute(res) {
    let data = JSON.parse(JSON.stringify(res));
    let valueData = {}
    if (data.fileAttribute && data.fileAttribute.hasOwnProperty("value")) {
        Object.keys(data).forEach(v => {
            if (v != "specialDatas") {
                valueData[v] = data[v].value;
            }
        })
        data = valueData;
    }
    let obj = {
        padFile: [],
        flashFile: [],
        shapeFile: [],
        bsmFile: [],
        osmFile: [],
        manual: [],
        model3d: [],
        symCds1: [],
        symCds2: [],
        symCds4: [],
        footPrintCds1: [],
        footPrintCds3:[],
        altiumPcbLibPath:[],
    }
    let fileAttribute = typeof data.fileAttribute ==="string"&&data.fileAttribute ? JSON.parse(data.fileAttribute) : data.fileAttribute;
    if (!fileAttribute) {fileAttribute={}}
    // console.log(fileAttribute);
    // console.log(data);
    Object.keys(obj).forEach(key => {
        if (data[key]) {
            obj[key] = dealData(key,data,fileAttribute);
        }
    })
    return obj;
}

// 找对应的状态，没有默认正式
function dealData(field,data,fileAttribute) {
    const sourceD = JSON.parse(JSON.stringify(data));
    let padF = [];
    let arr = [];
    if (field === "symCds1") {
        // 符号名称中可能有逗号
        let reg = /,([\s\S]*?).olb/i;
        if (reg.test(sourceD[field])) {
            let arr = sourceD[field].split(",");
            let data = [];
            arr.forEach((v,i) => {
                if (v.toLowerCase().includes(".olb")) {
                    data.push(v.trim());
                } else {
                    let str = data[data.length-1];
                    data[data.length-1] = `${str.trim()},${v.trim()}`
                }
            })
            padF = data;
        } else {
            padF = [sourceD[field]];
        }
    } else {
        padF = sourceD[field].split(",").filter(function (s) {
            return s && s.trim();
        });
    }
    if (padF.length > 0) {
        padF.forEach(v => {
            let status = "";
            let id = "";
            try {
                if (fileAttribute && fileAttribute[field] && fileAttribute[field].find(it => it.name === v)) {
                    status = fileAttribute[field].find(it => it.name === v).status;
                    id = fileAttribute[field].find(it => it.name === v).id;
                    if (!status) {
                        status = "正式";
                    }
                } else {
                    status = "正式";
                    id = "";
                }
            } catch(e) {
                console.log(e);
                // status = "正式";
                // id = "";
            }
            arr.push({
                name: v,
                status,
                id,
            });
        })
    }
    return arr;
}

export const configFilePath = "/config/config.json";

/**
 * 随机生成id
 * @param field
 * @returns {string}
 */
export function randomlyGeneratedId(field="") {
    return field + (Math.floor(Math.random() * (999999 - 100000)) + 100000).toString()
        + new Date().getTime().toString().substring(5);
}

/**
 * 删除最后一条数据后显示无数据问题 获取pageNum
 * @param pageNum
 * @param pageSize
 * @param total 总数
 * @param size 删除条数
 * @returns {number|number|*}
 */
export function getDleChangePage(pageNum,pageSize,total,size=1,) {
    // 删除之后还有几页
    const page = Math.ceil((total - size) / pageSize);
    const num = page < pageNum ? page : pageNum;
    return num < 1 ? 1 : num;
}

// 根据关键值检索目标值
export const findTargetValueByKey = (
    key, // String | Object
    originArr,
    comparativeField,
    childrenField,
    handleFunc,
    conditionFunc
) => {
    for (const item of originArr) {
        handleFunc && handleFunc(item[comparativeField], item) // 特殊处理
        // console.log(item[comparativeField], key)
        const condition = conditionFunc ? conditionFunc(item, key) : item[comparativeField] === key
        if (condition) {
            return item
        } else {
            if (item[childrenField] && item[childrenField].length) {
                const result = findTargetValueByKey(
                    key,
                    item[childrenField],
                    comparativeField,
                    childrenField,
                    handleFunc,
                    conditionFunc
                )
                if (result) return result
            }
        }
}
}

/**
 * 元器件列宽处理
 * @param clo
 * @returns {string}
 */
export function getTableWidthByField(clo) {
    switch (clo.dataField) {
        case "part_number" :
            // 8个汉字
            return "120"
        case "total_quantity" :
            // 表头长度
            return "95"
        case "ef19_" :
            // 表头长度
            return "95"
        case "manufacturer_" :
            // 6个汉字
            return "95"
        case "product_model" :
            // 8个汉字
            return "170"
        case "ef10_" :
            // 4个汉字
            return "85"
        case "pre_ranking" :
            // 表头长度
            return "95"
        case "life_cycle" :
            // 8个汉字
            return "95"
        case "work_temperature" :
            // 表头长度
            return "118"
        case "is_indicator" :
            // 4个汉字
            return "80"
        case "ef4_" :
            // 8个汉字
            return "120"
        case "update_time" :
            // 刚好时间长度
            return "145"
        case "create_time" :
            // 刚好时间长度
            return "145"
        case "ef6_" :
            // 刚好时间长度
            return "120"
        case "ef7_" :
            // 8个汉字
            return "140"
        case "ef12_" :
            // 6个汉字
            return "95"
        case "ef3_" :
            // 6个汉字
            return "250"
        case "other_version" :
            // 4个汉字
            return "95"
        default:
            return "200"
    }
}

// 导出的流Blob,filename 导出的文件名
export function downloadFileBlob(data, filename) {
    if (!data) {
        return
    }
    let fun = () => {
        let url = window.URL.createObjectURL(new Blob([data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
    }
    return new Promise((resolve,reject) => {
        let blob = new Blob([data]);
        let reader = new FileReader();
        reader.readAsText(blob, 'utf-8');
        reader.onload = function () {
            try {
                let data = JSON.parse(reader.result);
                Message.error(data?.msg);
                reject();
            } catch (e) {
                fun();
                resolve();
            }
        }
    })
}

// 获取blob中的提示信息
export function getBlobInfo(res) {
    return new Promise((resolve,reject) => {
        let blob = new Blob([res]);
        let reader = new FileReader();
        reader.readAsText(blob, 'utf-8');
        reader.onload = function () {
            try {
                let data = JSON.parse(reader.result);
                resolve(data);
            } catch (e) {
                resolve({});
            }
        }
    })
}

/**
 * 本地保存JSON数据
 * @param data
 * @param fileName
 */
export function saveDataJson(data, fileName) {
    // 将数据转换为JSON字符串
    const jsonData = JSON.stringify(data);

    // 创建一个Blob实例，其中包含JSON数据
    const blob = new Blob([jsonData], { type: 'application/json' });

    // 创建一个指向该Blob的URL
    const url = URL.createObjectURL(blob);

    // 创建一个a元素，用于触发下载
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = fileName + '.json'; // 下载文件的名称，加上.json扩展名

    // 添加a元素到文档中，并触发点击
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // 清理并移除元素和对象URL
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
}

/**
 * get请求参数编码
 * @param obj
 * @returns {string}
 */
export function getEncodeURIComponentPar(obj) {
    let str = "";
    Object.keys(obj).forEach(key => {
        str += `${key}=${encodeURIComponent(obj[key])}&`
    })
    return str.slice(0,str.length-1);
}
